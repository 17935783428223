import { FC } from "react";
import joinClassNames from "classnames";

import Icon from "./Icon";
import { BaseProps, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends BaseProps {
  className?: string;
  size?: Sizes;
  isBordered?: boolean;
  classNames?: Partial<{
    icon: string;
  }>;
}

const CircledIcon: FC<Props> = ({
  className,
  src: icon,
  size = "sm",
  isBordered = true,
  altImage,
  classNames = {},
}) => (
  <div
    data-size={size}
    className={joinClassNames(
      classes.sizePresets,
      classes.circledWrapper,
      className,
    )}
    data-bordered={isBordered}
  >
    <Icon src={icon} altImage={altImage} className={classNames.icon} />
  </div>
);

export default CircledIcon;
export type { Props as CircledIconProps };
